import React, { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Button, Heading } from 'grommet';

import { get } from '../../lib/nodash';
import DiscountedPriceLabel from '../Product/DiscountedPriceLabel';
import Loading from '../Loading';
import TextTitle from '../TextTitle';
import formatCurrency from '../../lib/formatCurrency';
import useCart from './useCart';
import selectJwt from '../../state/industry/selectJwt';
import { CHECKOUT_STARTED } from '../../lib/analytics/segmentActions';
import { track } from '../../lib/analytics';

const CartTotals = ({ items, checkoutId, ...rest }) => {
  const jwt = useSelector(selectJwt);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const clientId = useSelector((state) => state.cart.clientId);
  const { subtotal, preDiscountSubtotal, checkoutUrl, loading, savings } =
    useCart();
  const adjusting = false;
  const finalCheckoutUrl = useMemo(
    () =>
      `${(checkoutUrl || '').replace(
        'backdrop-home.myshopify.com',
        'checkout.backdrophome.com'
      )}${jwt ? `&jwt=${jwt}` : ''}${clientId ? `&clientId=${clientId}` : ''}`,
    [checkoutUrl]
  );

  return (
    <Box className="cart-totals" style={{ zIndex: 1 }} {...rest}>
      <Box
        className="minicart__totals"
        gap="small"
        pad={{
          horizontal: 'medium',
          vertical: 'medium',
        }}
      >
        {Math.abs(savings) > 0 && (
          <Box direction="row" className="row">
            <Box flex={true}>
              <Heading
                margin="0"
                level={5}
                className="sub-heading minicart__total-discount-heading"
                id="total-discount-heading"
              >
                Savings
              </Heading>
            </Box>
            <Box flex={true} align="end">
              <Heading
                level={5}
                margin="0"
                className="minicart__total-discount sub-heading"
                id="cart-total-discount"
                style={{ opacity: loading || adjusting ? 0.5 : 1 }}
              >
                {formatCurrency(Math.abs(savings))}
              </Heading>
            </Box>
          </Box>
        )}

        <Box direction="row" className="row">
          <Box flex={true}>
            <TextTitle size="small" className="sub-heading">
              Subtotal
            </TextTitle>
          </Box>
          <Box flex={true}>
            <Box direction="row" justify="end" gap="small">
              {Math.abs(savings) > 0 && (
                <DiscountedPriceLabel
                  fullPrice={preDiscountSubtotal}
                  isDiscounted={true}
                  size="0.93333rem"
                />
              )}
              <Heading
                level={5}
                margin="0"
                className="minicart__price sub-heading"
                id="btn__checkout-price"
                style={{ opacity: loading || adjusting ? 0.5 : 1 }}
              >
                {subtotal ? formatCurrency(subtotal) : '$0'}{' '}
              </Heading>
            </Box>
          </Box>
        </Box>
      </Box>
      {checkoutUrl && (
        <Box>
          <Button
            primary
            style={{ padding: '15px 30px', lineHeight: 3 }}
            label="Checkout Now"
            href={finalCheckoutUrl}
            disabled={items.length === 0 || checkoutLoading}
            reverse
            icon={checkoutLoading && <Loading />}
            onClick={async () => {
              track(CHECKOUT_STARTED, {
                order_id: checkoutId,
                value: subtotal,
                products: items.map((p, i) => ({
                  product_id: get('node.variant.product.id', p),
                  sku: get('node.variant.id', p),
                  variant: get('node.title', p),
                  name: get('node.variant.product.title', p),
                  price: parseFloat(get('node.variant.priceV2.amount', p)),
                  position: i,
                  category: get('node.variant.product.productType', p),
                  url: get('node.variant.product.onlineStoreUrl', p),
                  image_url: get('node.variant.image.thumb', p),
                })),
              });
              setCheckoutLoading(true);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

CartTotals.propTypes = {
  items: PropTypes.array.isRequired,
  checkoutId: PropTypes.string,
};

export default memo(CartTotals);
